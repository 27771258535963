import firebase from "firebase"
import { navigate } from "gatsby"
import { isBrowser } from "services/general"
import { addAddress, editAddress } from "./addresses"
import { getSignedInUser } from "../../Auth/services/user"
import { handleEmailLoginAfterResetPassword } from "../../Auth/services/signin"

import {
  GATSBY_TELECONSULT_WEBSITE_URL,
  GATSBY_UPDATE_PROFILE_RECORD,
} from "gatsby-env-variables"

import axios from "axios"

export const handleProfileEditSubmit = async (payload) => {
  let { setLoading, userData, dispatch, nextPath, redirectExternal } = payload
  switch (payload.module) {
    case "update-password":
      await handlePasswordUpdate({
        ...payload,
        callback: async () => {
          setLoading(false)
          dispatch({
            type: "SHOW_TOAST",
            payload: {
              message: `Password successfully updated.`,
              color: "success",
            },
          })
          let finalPath = nextPath ? nextPath : "/profile"
          if (finalPath !== "/profile") {
            navigate(finalPath, {
              state: {
                disableBackButton: true,
              },
            })
          } else {
            navigate("/")
          }
        },
        errorCallback: () => {
          setLoading(false)
        },
      })
      break

    case "edit":
      editProfile({
        ...payload,
        id: userData.id,
        callback: () => {
          setLoading(false)
          dispatch({
            type: "SHOW_TOAST",
            payload: {
              message: `Profile successfully updated.`,
              color: "success",
            },
          })
          if (redirectExternal) {
            if (isBrowser()) {
              let url = localStorage.getItem("url")
              let program = localStorage.getItem("program")
              if (url && program)
                window.location.href = `${GATSBY_TELECONSULT_WEBSITE_URL}booking/[$URL]/intake?program=[$PROGRAM]`
                  .replace("[$URL]", url)
                  .replace("[$PROGRAM]", program)
            }
          } else {
            let finalPath = nextPath ? nextPath : "/profile"
            navigate(finalPath)
          }
          navigate("/")
        },
        errorCallback: () => {
          setLoading(false)
        },
      })
      break

    case "add-address":
      addAddress({
        ...payload,
        callback: () => {
          setLoading(false)
          dispatch({
            type: "SHOW_TOAST",
            payload: {
              message: `Address successfully added.`,
              color: "success",
            },
          })
          navigate("/profile")
        },
      })
      break

    case "edit-address":
      editAddress({
        ...payload,
        callback: () => {
          setLoading(false)
          dispatch({
            type: "SHOW_TOAST",
            payload: {
              message: `Address successfully updated.`,
              color: "success",
            },
          })
          navigate("/profile")
        },
      })
      break

    default:
      break
  }
}

export const handlePasswordUpdate = async ({
  values,
  setMessage,
  callback,
  errorCallback,
}) => {
  let { userData } = getSignedInUser()
  let currentUserEmail = userData?.email

  try {
    await firebase
      .auth()
      .signInWithEmailAndPassword(currentUserEmail, values?.oldPassword)
    let user = firebase.auth().currentUser
    await user.updatePassword(values?.newPassword)

    // remove force reset
    await firebase
      .firestore()
      .collection("users")
      .doc(userData?.id)
      .update({ forceReset: false })

    sessionStorage.setItem(
      "userData",
      JSON.stringify({ ...userData, forceReset: false })
    )

    await handleEmailLoginAfterResetPassword({
      email: currentUserEmail,
      password: values?.newPassword,
    })

    if (callback) callback()
  } catch (error) {
    if (errorCallback) errorCallback()
    setMessage({
      type: "danger",
      content: { code: error.code, message: error.message },
    })
  }
}

export const editProfile = async ({
  id,
  values,
  callback,
  userData,
  setMessage,
  errorCallback,
}) => {
  try {
    if (isBrowser()) {
      let { month, date, year } = values.birthday

      let fieldsToUpdate = {
        firstName: values.firstName,
        lastName: values.lastName,
        mobileNumber: values.mobileNumber,
        birthday: {
          month: month.value,
          date: date.value,
          year: year.value,
        },
        site: values.site || "N/A",
        sex: values.sex,
        company: values.company || "Genpact",
        employeeNumber: values.employeeNumber || "N/A",
        department: values.department || "N/A",
        emergencyContact: {
          name: values.emergencyContactName,
          mobileNumber: values.emergencyContactNumber,
          relationship: values.emergencyContactRelationship.value,
        },
      }

      await firebase
        .firestore()
        .collection("users")
        .doc(id)
        .update(fieldsToUpdate)

      let newUserData = { ...userData, ...fieldsToUpdate }
      sessionStorage.setItem("userData", JSON.stringify(newUserData))

      await axios.post(GATSBY_UPDATE_PROFILE_RECORD, newUserData)
      if (callback) callback()
    }
  } catch (error) {
    if (errorCallback) errorCallback()
    setMessage({
      type: "danger",
      content: { code: error.code, message: error.message },
    })
  }
}
